import { ref, reactive , watch} from "vue"

interface ApiConfig{
  path: string, 
  method: string, 
  body: object, 
  transform: Function, 
  url: string|null,
  callback: object
}

export const ssrContent = ({path, method = 'GET', body = {}, transform = (item) => {return item}, callback = {}}: ApiConfig) => {
  let bodyParam = body

  const loadApi = async (page: Number = 1, url: string|null = null) => {
    bodyParam.page = page
    const data = await useJsonP({
      path: path, 
      method: method, 
      body: bodyParam, 
      transform: transform, 
      url: url,
      callback: callback
    })
    return data
  }

  return {loadApi}
}