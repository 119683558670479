import vClickOutside from "click-outside-vue3";
import vueRecaptcha from 'vue3-recaptcha2';
import {Notifications, notify} from '@kyvg/vue3-notification'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.provide('notify', notify)
    nuxtApp.vueApp.use(vClickOutside);
    nuxtApp.vueApp.component('VueRecaptcha', vueRecaptcha)
    nuxtApp.vueApp.component('Notifications', Notifications)
});
