export default defineNuxtRouteMiddleware(({ path, query, hash }) => {
    if (path === '/' || !path.endsWith('/')) return
  
    let nextPath: string = path.replace(/\/+$/, '') || '/'

    nextPath = nextPath.replace(/^\/(news|amp\/article)\/([0-9]+\/.*)$/g, '/article/$2')

    const nextRoute = { path: nextPath, query, hash }
  
    return navigateTo(nextRoute, { redirectCode: 301 })
  })
