import { default as _91slug_93gT8qLaZmJHMeta } from "/var/www/html/pages/[slug].vue?macro=true";
import { default as about9BEUa3hPycMeta } from "/var/www/html/pages/about.vue?macro=true";
import { default as _91slug_939xIZU8kA8DMeta } from "/var/www/html/pages/amp/article/[id]/[slug].vue?macro=true";
import { default as _91slug_93Mj9cvcStR7Meta } from "/var/www/html/pages/article/[id]/[slug].vue?macro=true";
import { default as _91page_93PMo72Swn6iMeta } from "/var/www/html/pages/article/category/[id]/[slug]/[page].vue?macro=true";
import { default as indexY8xfks4xdvMeta } from "/var/www/html/pages/article/category/[id]/[slug]/index.vue?macro=true";
import { default as index8aWTfZBWIjMeta } from "/var/www/html/pages/article/index.vue?macro=true";
import { default as searchYd1SbolbcgMeta } from "/var/www/html/pages/article/search.vue?macro=true";
import { default as contactZjsR3yTbTmMeta } from "/var/www/html/pages/contact.vue?macro=true";
import { default as enervonactiveQzif2fQRnyMeta } from "/var/www/html/pages/enervonactive.vue?macro=true";
import { default as enervonc0DtpfyawAhMeta } from "/var/www/html/pages/enervonc.vue?macro=true";
import { default as enervongoldCEVCf6YgedMeta } from "/var/www/html/pages/enervongold.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as levelupwithenervonactiveCkN1ObeLQ8Meta } from "/var/www/html/pages/levelupwithenervonactive.vue?macro=true";
import { default as muda_45terus_45aktif_45terusL32E7iigQnMeta } from "/var/www/html/pages/muda-terus-aktif-terus.vue?macro=true";
import { default as _91slug_93Xm2IuZ0eO5Meta } from "/var/www/html/pages/news/[id]/[slug].vue?macro=true";
import { default as _91slug_93n2z7ha9ZpaMeta } from "/var/www/html/pages/tag/s/[slug].vue?macro=true";
import { default as component_45stubIAHYdykPzVMeta } from "/home/enervoncoid/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubIAHYdykPzV } from "/home/enervoncoid/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91slug_93gT8qLaZmJHMeta?.name ?? "slug",
    path: _91slug_93gT8qLaZmJHMeta?.path ?? "/:slug()",
    meta: _91slug_93gT8qLaZmJHMeta || {},
    alias: _91slug_93gT8qLaZmJHMeta?.alias || [],
    redirect: _91slug_93gT8qLaZmJHMeta?.redirect,
    component: () => import("/var/www/html/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: about9BEUa3hPycMeta?.name ?? "about",
    path: about9BEUa3hPycMeta?.path ?? "/about",
    meta: about9BEUa3hPycMeta || {},
    alias: about9BEUa3hPycMeta?.alias || [],
    redirect: about9BEUa3hPycMeta?.redirect,
    component: () => import("/var/www/html/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939xIZU8kA8DMeta?.name ?? "amp-article-id-slug",
    path: _91slug_939xIZU8kA8DMeta?.path ?? "/amp/article/:id()/:slug()",
    meta: _91slug_939xIZU8kA8DMeta || {},
    alias: _91slug_939xIZU8kA8DMeta?.alias || [],
    redirect: _91slug_939xIZU8kA8DMeta?.redirect,
    component: () => import("/var/www/html/pages/amp/article/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Mj9cvcStR7Meta?.name ?? "article-id-slug",
    path: _91slug_93Mj9cvcStR7Meta?.path ?? "/article/:id()/:slug()",
    meta: _91slug_93Mj9cvcStR7Meta || {},
    alias: _91slug_93Mj9cvcStR7Meta?.alias || [],
    redirect: _91slug_93Mj9cvcStR7Meta?.redirect,
    component: () => import("/var/www/html/pages/article/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91page_93PMo72Swn6iMeta?.name ?? "article-category-id-slug-page",
    path: _91page_93PMo72Swn6iMeta?.path ?? "/article/category/:id()/:slug()/:page()",
    meta: _91page_93PMo72Swn6iMeta || {},
    alias: _91page_93PMo72Swn6iMeta?.alias || [],
    redirect: _91page_93PMo72Swn6iMeta?.redirect,
    component: () => import("/var/www/html/pages/article/category/[id]/[slug]/[page].vue").then(m => m.default || m)
  },
  {
    name: indexY8xfks4xdvMeta?.name ?? "article-category-id-slug",
    path: indexY8xfks4xdvMeta?.path ?? "/article/category/:id()/:slug()",
    meta: indexY8xfks4xdvMeta || {},
    alias: indexY8xfks4xdvMeta?.alias || [],
    redirect: indexY8xfks4xdvMeta?.redirect,
    component: () => import("/var/www/html/pages/article/category/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index8aWTfZBWIjMeta?.name ?? "article",
    path: index8aWTfZBWIjMeta?.path ?? "/article",
    meta: index8aWTfZBWIjMeta || {},
    alias: index8aWTfZBWIjMeta?.alias || [],
    redirect: index8aWTfZBWIjMeta?.redirect,
    component: () => import("/var/www/html/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: searchYd1SbolbcgMeta?.name ?? "article-search",
    path: searchYd1SbolbcgMeta?.path ?? "/article/search",
    meta: searchYd1SbolbcgMeta || {},
    alias: searchYd1SbolbcgMeta?.alias || [],
    redirect: searchYd1SbolbcgMeta?.redirect,
    component: () => import("/var/www/html/pages/article/search.vue").then(m => m.default || m)
  },
  {
    name: contactZjsR3yTbTmMeta?.name ?? "contact",
    path: contactZjsR3yTbTmMeta?.path ?? "/contact",
    meta: contactZjsR3yTbTmMeta || {},
    alias: contactZjsR3yTbTmMeta?.alias || [],
    redirect: contactZjsR3yTbTmMeta?.redirect,
    component: () => import("/var/www/html/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: enervonactiveQzif2fQRnyMeta?.name ?? "enervonactive",
    path: enervonactiveQzif2fQRnyMeta?.path ?? "/enervonactive",
    meta: enervonactiveQzif2fQRnyMeta || {},
    alias: enervonactiveQzif2fQRnyMeta?.alias || [],
    redirect: enervonactiveQzif2fQRnyMeta?.redirect,
    component: () => import("/var/www/html/pages/enervonactive.vue").then(m => m.default || m)
  },
  {
    name: enervonc0DtpfyawAhMeta?.name ?? "enervonc",
    path: enervonc0DtpfyawAhMeta?.path ?? "/enervonc",
    meta: enervonc0DtpfyawAhMeta || {},
    alias: enervonc0DtpfyawAhMeta?.alias || [],
    redirect: enervonc0DtpfyawAhMeta?.redirect,
    component: () => import("/var/www/html/pages/enervonc.vue").then(m => m.default || m)
  },
  {
    name: enervongoldCEVCf6YgedMeta?.name ?? "enervongold",
    path: enervongoldCEVCf6YgedMeta?.path ?? "/enervongold",
    meta: enervongoldCEVCf6YgedMeta || {},
    alias: enervongoldCEVCf6YgedMeta?.alias || [],
    redirect: enervongoldCEVCf6YgedMeta?.redirect,
    component: () => import("/var/www/html/pages/enervongold.vue").then(m => m.default || m)
  },
  {
    name: indexUbNeHw0dYYMeta?.name ?? "index",
    path: indexUbNeHw0dYYMeta?.path ?? "/",
    meta: indexUbNeHw0dYYMeta || {},
    alias: indexUbNeHw0dYYMeta?.alias || [],
    redirect: indexUbNeHw0dYYMeta?.redirect,
    component: () => import("/var/www/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: levelupwithenervonactiveCkN1ObeLQ8Meta?.name ?? "levelupwithenervonactive",
    path: levelupwithenervonactiveCkN1ObeLQ8Meta?.path ?? "/levelupwithenervonactive",
    meta: levelupwithenervonactiveCkN1ObeLQ8Meta || {},
    alias: levelupwithenervonactiveCkN1ObeLQ8Meta?.alias || [],
    redirect: levelupwithenervonactiveCkN1ObeLQ8Meta?.redirect,
    component: () => import("/var/www/html/pages/levelupwithenervonactive.vue").then(m => m.default || m)
  },
  {
    name: muda_45terus_45aktif_45terusL32E7iigQnMeta?.name ?? "muda-terus-aktif-terus",
    path: muda_45terus_45aktif_45terusL32E7iigQnMeta?.path ?? "/muda-terus-aktif-terus",
    meta: muda_45terus_45aktif_45terusL32E7iigQnMeta || {},
    alias: muda_45terus_45aktif_45terusL32E7iigQnMeta?.alias || [],
    redirect: muda_45terus_45aktif_45terusL32E7iigQnMeta?.redirect,
    component: () => import("/var/www/html/pages/muda-terus-aktif-terus.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Xm2IuZ0eO5Meta?.name ?? "news-id-slug",
    path: _91slug_93Xm2IuZ0eO5Meta?.path ?? "/news/:id()/:slug()",
    meta: _91slug_93Xm2IuZ0eO5Meta || {},
    alias: _91slug_93Xm2IuZ0eO5Meta?.alias || [],
    redirect: _91slug_93Xm2IuZ0eO5Meta?.redirect,
    component: () => import("/var/www/html/pages/news/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93n2z7ha9ZpaMeta?.name ?? "tag-s-slug",
    path: _91slug_93n2z7ha9ZpaMeta?.path ?? "/tag/s/:slug()",
    meta: _91slug_93n2z7ha9ZpaMeta || {},
    alias: _91slug_93n2z7ha9ZpaMeta?.alias || [],
    redirect: _91slug_93n2z7ha9ZpaMeta?.redirect,
    component: () => import("/var/www/html/pages/tag/s/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/1154/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/167/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/3349/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/3694/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/4686/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/4919/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/671/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/article/692/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/coronavirus/*",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/coronavirus",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/pixel",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/tiktok",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  },
  {
    name: component_45stubIAHYdykPzVMeta?.name ?? undefined,
    path: component_45stubIAHYdykPzVMeta?.path ?? "/gads",
    meta: component_45stubIAHYdykPzVMeta || {},
    alias: component_45stubIAHYdykPzVMeta?.alias || [],
    redirect: component_45stubIAHYdykPzVMeta?.redirect,
    component: component_45stubIAHYdykPzV
  }
]