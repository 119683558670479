interface ApiConfig{
  path: string, 
  method: string, 
  body: object, 
  transform: Function, 
  url: string|null,
  callback: object
}

export const useJsonP = async ({path, method = 'GET', body = {}, transform = (item) => {return item}, url = null, callback = {}}: ApiConfig) => {
  const config = useRuntimeConfig()
  let baseURL

  if(url != null){
    baseURL = url
  }else{
    baseURL = config.apiBase
  }

  const options = {
    transform: transform,
    method: method,
    body: body,
    baseURL: baseURL,
    key: String(Math.random())
  }

  const mergedOptions = {
    ...options,
    ...callback,
  }

  const {data, error} =  await useFetch(`${path}`, mergedOptions)

  if(error.value !== null)
    console.log(error)

  return data
}