export default defineNuxtPlugin(() => {
    onNuxtReady(async () => {
        // function gtag() {
        //     window.dataLayer.push(arguments);
        //   }
          
        // window.dataLayer = window.dataLayer || [];
        
        // gtag('js', new Date());
        // gtag('config', 'G-BQYPH5XX1M', {
        //     allow_google_signals : false
        // });

        // !function(f,b,e,v,n,t,s)
        // {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        // n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        // if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        // n.queue=[];t=b.createElement(e);t.defer=!0;
        // t.src=v;s=b.getElementsByTagName(e)[0];
        // s.parentNode.insertBefore(t,s)}(window, document,'script',
        // 'https://connect.facebook.net/en_US/fbevents.js');
        // fbq('init', '1622515641250969');
        // fbq('track', 'PageView');

        // useHead({
        //     script: [
        //         { src: 'https://www.googletagmanager.com/gtag/js?id=G-BQYPH5XX1M', defer: true, body: true },
        //     ]
        // })
    })
})
