import revive_payload_client_ia3SwUKd6u from "/home/enervoncoid/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g9OFhmBNWa from "/home/enervoncoid/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pxChp4oqwT from "/home/enervoncoid/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_deskQtVUKU from "/home/enervoncoid/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_0CwwXcB73w from "/home/enervoncoid/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_brssSm2FSy from "/home/enervoncoid/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_j5EGOwVtAS from "/home/enervoncoid/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import chunk_reload_client_p5GmcM1EkV from "/home/enervoncoid/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import pluginvue_YPXXvhiE3X from "/var/www/html/plugins/pluginvue.ts";
import ready_client_VDAUw9EeQx from "/var/www/html/plugins/ready.client.ts";
export default [
  revive_payload_client_ia3SwUKd6u,
  unhead_g9OFhmBNWa,
  router_pxChp4oqwT,
  payload_client_deskQtVUKU,
  check_outdated_build_client_0CwwXcB73w,
  components_plugin_KR1HBZs4kY,
  prefetch_client_brssSm2FSy,
  plugin_j5EGOwVtAS,
  chunk_reload_client_p5GmcM1EkV,
  pluginvue_YPXXvhiE3X,
  ready_client_VDAUw9EeQx
]